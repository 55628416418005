<template>
  <div class="container">
    <div class="">
      <div class="row" :class="user && user.isPatient ? 'mt-4' : ''">
        <div class="col px-0 px-lg-3">
          <alert v-if="documents.isLoading" class="my-4 light-shadow" />
          <div>
            <alert
              v-if="!documents.isLoading && documents.data.length == 0"
              class="m-4 light-shadow"
              >No Invoices Generated</alert
            >
          </div>
          <div
            class="d-flex justify-content-sm-end mt-4"
            v-if="!documents.isLoading && documents.data.length > 0"
          >
            <div class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                id="hide_deleted"
                v-model="documents.hideDeleted"
              />
              <label class="custom-control-label mr-3 mb-3" for="hide_deleted"
                >Hide deleted sessions from invoices</label
              >
            </div>
          </div>
          <div
            class="
              card
              shadow-card
              mobile-tablet-no-card
              table-container-force
              border border-lg-0
              table-container
            "
            v-if="!documents.isLoading && documents.data.length > 0"
          >
            <table
              class="table table-striped mb-0 responsive-mobile-tablet-table"
            >
              <thead>
                <tr class="desktop-only-force">
                  <th class="col-3">File Type</th>
                  <th class="col-3">Invoice No.</th>
                  <th class="col-3">Date</th>
                  <th class="col-1">Status</th>
                  <th class="col-2 text-center">Action</th>
                </tr>
              </thead>
              <tr v-for="d in documents.data" :key="d.id">
                <td>
                  <b class="mobile-tablet-only mr-2">File Type:</b>
                  <icon :type="`file-pdf`" class="fa-2x text-muted m-0" />
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Invoice No:</b>Invoice
                  {{ d.id }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Date:</b
                  >{{ prettyDate(d.created_at) }}
                </td>
                <td>
                  <b class="mobile-tablet-only mr-2">Status:</b
                  ><span :class="d.paid_at ? 'text-success' : 'text-danger'">{{
                    d.paid_at ? "Paid" : "Not Paid"
                  }}</span>
                </td>
                <td class="text-right td-actions">
                  <button
                    @click="viewEdit(d)"
                    class="btn btn-sm btn-deep-success mt-2"
                    :disabled="
                      d.isDownloading || d.isDeleting || documents.isDownloading
                    "
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isDownloading"
                    ></span>
                    <span v-else
                      >View{{
                        user && (user.isAdmin || user.isManagingAdmin)
                          ? "/Edit"
                          : ""
                      }}</span
                    >
                  </button>

                  <button
                    @click="print(d)"
                    class="btn btn-sm btn-theme mt-2 ml-2"
                    :disabled="
                      d.isDownloading || d.isDeleting || documents.isDownloading
                    "
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isDownloading"
                    ></span>
                    <span v-else>Print</span>
                  </button>
                  <button
                    v-if="user && (user.isAdmin || user.isManagingAdmin)"
                    @click="copyShareableLink(d)"
                    class="btn btn-sm btn-black mt-2 ml-2"
                    :disabled="generateShareableLinkLoading == d.id"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="generateShareableLinkLoading == d.id"
                    ></span>
                    <span v-else> <icon type="link" /> Share </span>
                  </button>
                  <button
                    v-if="
                      user &&
                      (user.isAdmin || user.isManagingAdmin) &&
                      $can({ key: 'clients', expectedPermission: 'edit' })
                    "
                    :disabled="
                      d.isDeleting || d.isDownloading || documents.isDeleting
                    "
                    @click="deleteDocumentModal(d)"
                    class="btn btn-sm btn-danger mt-2 ml-2"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      v-if="d.isDeleting"
                    ></span>
                    <span v-else>Delete</span>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <modal
      class="invoice-item-modal"
      name="invoice-item-modal"
      transition="pop-out"
      :clickToClose="false"
      width="94%"
      height="90%"
    >
      <div class="position-relative h-100">
        <div class="vm--top-right-slot" style="z-index: 1">
          <h3
            class="position-absolute p-3 pr-5 text-left border-bottom col-12"
            style="letter-spacing: 0.1px"
            v-if="selectedDocumentToView"
          >
            Invoice No.{{ selectedDocumentToView.id }} &nbsp;&nbsp;&nbsp;<span
              style="font-size: 14px"
              class="text-secondary"
              >({{ prettyDate(selectedDocumentToView.created_at) }})</span
            >
          </h3>
          <button
            class="btn p-0 pr-3 pt-3 border-0 z-100 position-relative"
            :disabled="
              selectedDocumentToView && selectedDocumentToView.updatingStatus
            "
            @click="closeInvoiceItemModal"
          >
            <i class="fas fa-times fa-lg"></i>
          </button>
        </div>
        <div class="card-body mt-3">
          <div
            class="d-flex justify-content-end"
            v-if="
              selectedDocumentToView &&
              user &&
              (user.isAdmin || user.isManagingAdmin)
            "
          >
            <div class="form-group row mx-0 align-items-center">
              <label class="col-auto mb-0" for="input-control-paid_date"
                >Paid at</label
              >
              <div class="col-auto position-relative">
                <datetime
                  id="input-control-paid_date"
                  v-model="selectedDocumentToView.paid_at"
                  placeholder=""
                  :week-start="7"
                  zone="UTC"
                  format="yyyy-MM-dd"
                  input-class="form-control"
                ></datetime>
                <i
                  class="fas fa-lg fa-times clear-paid-icn text-danger"
                  @click="selectedDocumentToView.paid_at = null"
                ></i>
              </div>
              <button
                @click="updatePaidStatus(selectedDocumentToView)"
                class="btn btn-deep-success pl-4 pr-4"
                :disabled="selectedDocumentToView.updatingStatus"
              >
                <span
                  class="spinner-border spinner-border-sm"
                  v-if="selectedDocumentToView.updatingStatus"
                ></span>
                <span v-else>Save</span>
              </button>
            </div>
          </div>
          <Invoice
            v-if="selectedDocumentToView"
            :invoiceData="invoiceData"
            :noPrint="true"
            :hideDeleted="documents.hideDeleted"
          />
          <div class="text-center action-container p-3 border-top">
            <button
              class="btn btn-outline-blue"
              @click="closeInvoiceItemModal"
              :disabled="
                selectedDocumentToView && selectedDocumentToView.updatingStatus
              "
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </modal>
    <Invoice
      :invoiceData="invoiceData"
      :hideDeleted="documents.hideDeleted"
      v-if="showPrint"
      @printed="postPrint"
      class="invoiceTemplate"
    />
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import helpers from "@/utils/helpers";
import Swal from "sweetalert2";
import Invoice from "@/components/Invoice.vue";
import { Datetime } from "vue-datetime";

export default {
  name: "invoices",
  components: { Invoice, datetime: Datetime },
  async mounted() {
    try {
      await this.generate();

      //Set the address for the user
      if (this.patient.user.addresses[0]) {
        let address = this.patient.user.addresses[0];
        this.patientInfo.userAddressLine1 =
          address.house + " " + address.street;
        this.patientInfo.userAddressLine2 =
          address.city + " " + address.state + " " + address.zip;
      }
      //Set client name
      this.patientInfo.first = this.patient.user.first;
      this.patientInfo.last = this.patient.user.last;

      //Set ID:
      this.patientInfo.id = this.patient.user_id;

      //Set Company Information
      await this.getCompanyProfile();

      this.companyInfo = {
        name: this.companyProfile.show_name_in_report
          ? this.companyProfile.name
          : "",
        location: this.companyProfile.show_location_in_report
          ? this.companyProfile.location
          : "",
        phone_number: this.companyProfile.show_phone_number_in_report
          ? this.companyProfile.phone_number
          : "",
        email: this.companyProfile.show_email_in_report
          ? this.companyProfile.email
          : "",
        url: this.companyProfile.show_url_in_report
          ? this.companyProfile.url
          : "",
        logo:
          this.companyProfile.logo?.split("?response-conten")[0] ||
          this.companyLogoPlaceholder, //Default C3 Logo
      };
    } catch (err) {
      //eslint-disable-next-line
      console.log(err);
    }
  },
  data() {
    return {
      documents: {
        isLoading: true,
        isDownloading: false,
        hideDeleted: false,
        data: [],
      },
      selectedDocumentToView: undefined,
      patientInfo: {
        first: "",
        last: "",
        userAddressLine1: "",
        userAddressLine2: "",
        id: "",
      },
      companyInfo: {
        name: "",
        location: "",
        phone_number: "",
        email: "",
        url: "",
        logo: "",
      },
      showPrint: false,
      invoiceData: {},
      companyLogoPlaceholder: require("@/assets/img/c3-logo.png"),
    };
  },
  computed: {
    ...mapState({
      patient: (state) => state.patients.patient,
      user: (state) => state.auth.user,
      companyProfile: (state) => state.settings.companyProfile,
      generateShareableLinkLoading: (state) =>
        state.invoices.generateShareableLinkLoading,
    }),
  },
  methods: {
    ...mapActions({
      getGeneratedInvoices: "invoices/generatedInvoices",
      getInvoicesByIds: "invoices/getByIds",
      deleteInvoice: "invoices/delete",
      updateStatus: "invoices/updatePaidStatus",
      getCompanyProfile: "settings/getCompanyProfile",
      generateShareableLink: "invoices/generateShareableLink",
    }),
    generate: async function () {
      //Retrieve Generated invoices for the user
      this.documents.data = await this.getGeneratedInvoices({
        id: this.patient.user_id,
      });
      this.documents.isLoading = false;
    },
    postPrint() {
      this.showPrint = false;
    },
    async viewEdit(doc) {
      this.selectedDocumentToView = undefined;
      await this.print(doc, true);
    },
    async print(doc, viewOnly = false) {
      if (!viewOnly) {
        this.showPrint = true; // Allows document to render for print
      }
      doc.isDownloading = true;
      this.documents.isDownloading = true;

      // Retrieves Generated Invoice to access totals
      let invoice = this.documents.data.filter((row) => {
        return doc.id == row.id;
      });

      const invoice_list = invoice[0].invoice_list.split("-");
      const data = await this.getInvoicesByIds({
        invoices_ids: invoice_list,
        ...(!this.documents.hideDeleted
          ? {
              all: 1,
            }
          : {}),
      });
      if (!(data || []).length) {
        doc.isDownloading = false;
        this.documents.isDownloading = false;
        return Swal.fire(
          "Invoice Warning",
          "Invoice has no data to show",
          "warning"
        );
      }

      if (viewOnly) {
        this.selectedDocumentToView = {
          ...doc,
          paid_at: doc.paid_at
            ? `${doc.paid_at.split("T")[0]}T00:00:00.000Z`
            : doc.paid_at,
        };
        this.$modal.show("invoice-item-modal");
      }
      setTimeout(() => {
        // Set data to engage watcher in invoice
        this.invoiceData = {
          totals: invoice[0],
          billingData: data.filter((item) => item.appointment),
          patientInfo: this.patientInfo,
          companyInfo: this.companyInfo,
        };
      }, 50);
      doc.isDownloading = false;
      this.documents.isDownloading = false;
    },
    updatePaidStatus: function (document) {
      document.updatingStatus = true;
      this.$forceUpdate();
      this.updateStatus({
        id: document.id,
        paid_date: document.paid_at ? document.paid_at.split("T")[0] : null,
      }).then(() => {
        document.updatingStatus = false;
        this.$forceUpdate();
        this.generate();
        this.viewEdit(document);
      });
    },
    closeInvoiceItemModal: function () {
      this.$modal.hide("invoice-item-modal");
    },
    prettyDate: function (date) {
      return helpers.prettyDate(date);
    },
    deleteDocumentModal(doc) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-black ml-4",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure? Invoice " + doc.id + " will be deleted.",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete Invoice " + doc.id + " !",
          cancelButtonText: "No, cancel!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            doc.isDeleting = true;
            this.documents.isDeleting = true;
            this.$forceUpdate();
            this.deleteInvoice(doc.id).then((deleted) => {
              doc.isDeleting = false;
              this.documents.isDeleting = false;
              this.$forceUpdate();
              if (deleted) {
                swalWithBootstrapButtons.fire(
                  "Deleted!",
                  "Your Invoice has been deleted.",
                  "success"
                );

                ///Reload available generated invoices
                this.generate();
              } else {
                swalWithBootstrapButtons.fire(
                  "Error!",
                  "Something went wrong...",
                  "error"
                );
              }
            });
          }
        });
    },
    copyShareableLink: async function (invoice) {
      const { token } = await this.generateShareableLink({
        id: invoice.id,
      });
      const urlToCopy = `${window.location.origin}/invoices/${token}`;
      navigator.clipboard.writeText(urlToCopy).then(
        function () {
          Swal.fire({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            icon: "success",
            title: "The shareable link has been copied to your clipboard.",
          });
        },
        function (err) {
          console.error("Async: Could not copy text: ", err);
        }
      );
    },
  },
};
</script>
<style scoped lang="scss">
.invoiceTemplate {
  right: -5000px;
  overflow: hidden;

  @media screen {
    display: none;
  }
}
.clear-paid-icn {
  position: absolute;
  top: 12px;
  right: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.3);
  }
}
</style>
<style lang="scss">
.invoice-item-modal {
  .v--modal-box {
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
    left: 0 !important;
  }
  .invoice-item-container {
    padding: 12px !important;
    border: 1px solid #e3ebf6;
  }
  .card-body {
    height: calc(100% - 120px);
    overflow: auto;
  }
  .action-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
